import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { Link, Typography } from "@material-ui/core";
import logo from "../images/karolinska.png";

export const Copyright = () => {
    return (
        <>
            <Box mt={5}>
                <Grid container justify="center">
                    <Grid item>
                        <img src={logo} height={90} />
                    </Grid>
                </Grid>
                <Typography variant="body2" color="textSecondary" align="center" className="p2">
                    {'Copyright © '}
                    <Link color="inherit" href="#">
                    HPV-Vaccination
                    </Link>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
            </Box>
        </>
    );
}