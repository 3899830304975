import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Button } from '@material-ui/core';

export const VaccineHistory = () => {

    const [toVaccineHistory, setToVaccineHistory] = useState(false);

    const handleClick = () => {
        setToVaccineHistory(true);
    }

    if (toVaccineHistory) {
        return <Redirect to="/vaccine_history" />;
    }

    return (
        <Button
            color="primary"
            onClick={handleClick}>
            Vaccinationshistorik
        </Button>
    );
}
