import './App.css';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { LoginPage } from "./pages/LoginPage";
import { QuestionnairePage } from "./pages/QuestionnairePage";
import { QuestionPage } from "./pages/QuestionPage";
import { RegisterPage } from "./pages/RegisterPage";
import { NextQuestionnairePage } from './pages/NextQuestionnairePage';

import { ThemeProvider, withStyles } from "@material-ui/core/styles";
import { FirstPage } from './pages/FirstPage';
import { ConsentPage } from './pages/ConsentPage';
import { CallbackPage } from './pages/CallbackPage';
import { VaccineHistoryPage } from './pages/VaccineHistoryPage';

import { theme } from "./CustomTheme";


/* const styles = theme => ({
    "@global": {
        // MUI typography elements use REMs, so you can scale the global
        // font size by setting the font-size on the <html> element.
        html: {
            fontSize: 20,
            [theme.breakpoints.up("sm")]: {
                fontSize: 20
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 24
            },
            [theme.breakpoints.up("lg")]: {
                fontSize: 28
            }
        }
    }
}); */


function App() {
    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <HashRouter>
                    <Switch>
                        {/* <Route exact path="/" component={FirstPage} /> */}
                        <Route exact path="/" component={ConsentPage} />
                        <Route exact path="/grandid_callback" component={CallbackPage} />
                        <Route exact path="/login" component={LoginPage} />
                        <Route exact path="/questionnaire" component={QuestionnairePage} />
                        <Route exact path="/next_questionnaire" component={NextQuestionnairePage} />
                        <Route exact path="/question/unanswered_by_questionnaire/:questionnaireId/:groupQuestionnaireId" component={QuestionPage} />
                        <Route exact path="/register" component={RegisterPage} />
                        <Route exact path="/vaccine_history" component={VaccineHistoryPage} />
                    </Switch>
                </HashRouter>
            </div>
        </ThemeProvider>
    );
}

//export default withStyles(styles)(App);
export default App;
