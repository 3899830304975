import { Box, Grid, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { Copyright } from "../components/Copyright";
import { Login } from "../components/Login";
import { Register } from "../components/Register";
import { RegisterPersonNumber } from "../components/RegisterPersonNumber";
import * as AuthenticationUtil from "../util/AuthenticationUtil";
import * as Endpoints from "../util/Endpoints";
import * as ApiUtil from "../util/ApiUtil";

import { usePageLoader } from '../util/usePageLoader';
import { useAlert } from '../util/useAlert';

import logo from "../images/logo-hpv-vaccination.png";

export const LoginPage = () => {

    const [redirectUrl, setRedirectUrl] = useState();
    const [proceedRedirect, setProceedRedirect] = useState();

    const [alert, showAlert] = useAlert();
    const [loader, showLoader, hideLoader] = usePageLoader();

    const [welcomeText, setWelcomeText] = useState();
    const [open, setOpen] = useState(true);

    const [regionList, setRegionList] = useState();
    const [siteList, setSiteList] = useState();

    useEffect(() => {
        const getWelcomeText = async () => {
            try {
                showLoader();
                const responseData = await ApiUtil.callBackend(Endpoints.welcomeThankYouText, "GET");

                if (!responseData.status) {
                    showAlert(responseData.message, "error");
                }
                setWelcomeText(responseData.data.welcome.welcomeTextSwedish);
            } catch (error) {
                console.log(error);
                showAlert(error.message, "error");
            } finally {
                hideLoader();
            }
        }
        getWelcomeText();
    }, []);

    useEffect(() => {
        const getRegion = async () => {
            try {
                showLoader();
                let apiUrl = Endpoints.regions;
                const responseData = await ApiUtil.callBackend(apiUrl, "GET");

                if (responseData.status) {
                    setRegionList(responseData.data.list);
                } else {
                    showAlert(responseData.message, "error");
                }
            } catch (error) {
                console.log(error);
                showAlert(error.message, "error");
            } finally {
                hideLoader();
            }
        }
        getRegion();
    }, []);


    useEffect(() => {
        const getSite = async () => {
            try {
                showLoader();
                let apiUrl = Endpoints.sites;
                const responseData = await ApiUtil.callBackend(apiUrl, "GET");

                if (responseData.status) {
                    setSiteList(responseData.data.list);
                } else {
                    showAlert(responseData.message, "error");
                }
            } catch (error) {
                console.log(error);
                showAlert(error.message, "error");
            } finally {
                hideLoader();
            }
        }
        getSite();
    }, []);


    if (AuthenticationUtil.isAuthenticated()) {
        return <Redirect to="/questionnaire" />;
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleClickLogin = () => {
        const getRedirectUrl = async () => {
            try {
                showLoader();
                const responseData = await ApiUtil.callBackend(Endpoints.registrationUrl, "POST");

                if (!responseData.status) {
                    showAlert(responseData.message, "error");
                }
                setRedirectUrl(responseData.data.redirectResponse.redirectUrl);
                setProceedRedirect(true);
            } catch (error) {
                console.log(error);
                showAlert(error.message, "error");
            } finally {
                hideLoader();
            }
        }
        getRedirectUrl();
    };

    if (proceedRedirect && redirectUrl) {
        window.location = redirectUrl;
    }

    return (
        <div>
          <Box m={1}>
                <Grid container justify="center" align="center" spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <img src={logo} height={90} fullWidth />
                    </Grid>
                    <Paper>
                        <Box p={2}>
                            <Box mb={1}>
                                <Grid>
                                    <Register regionList={regionList} siteList={siteList}/>
                                </Grid>
                            </Box>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Button color="primary" onClick={handleClickLogin}>Logga in med BankID</Button>
                            </Grid>
                            <Box mb={1}>
                                <Grid>
                                    <RegisterPersonNumber regionList={regionList} siteList={siteList}/>
                                </Grid>
                            </Box>
                            <Box mt={3}>
                                <Login />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
              </Box>
         <Copyright />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Välkommen"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{welcomeText}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">OK</Button>
                </DialogActions>
            </Dialog>
            {alert}
            {loader}
        </div>
    );
}
