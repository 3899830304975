import '../App.css';
import { Box, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Redirect } from "react-router-dom";
import * as AuthenticationUtil from "../util/AuthenticationUtil";
import { Questionnaire } from '../components/Questionnaire';
import { usePageLoader } from "../util/usePageLoader";
import { useAlert } from "../util/useAlert";

import { Copyright } from "../components/Copyright";
import * as Endpoints from "../util/Endpoints";
import * as ApiUtil from "../util/ApiUtil";

import { Logout } from "../components/Logout";
import { VaccineHistory } from '../components/VaccineHistory';

export const QuestionnairePage = (props) => {

    const [response, setResponse] = useState({});

    const [loader, showLoader, hideLoader] = usePageLoader();
    const [alert, showAlert] = useAlert();

    useEffect(
        () => {

            const getActiveGroupQuestionnaire = async () => {
                try {
                    showLoader();
                    const responseData = await ApiUtil.callBackend(Endpoints.activeGroupQuestionnaire, "GET");
                    console.log(responseData);

                    setResponse(responseData);
                    //localStorage.setItem("current_group", "NeedToAskFirst");

                    if (!responseData.status) {
                        showAlert(responseData.message, "error");
                    }
                } catch (error) {
                    console.log(error);
                    showAlert(error.message, "error");
                } finally {
                    hideLoader();
                }
            }
            getActiveGroupQuestionnaire();

        }, []);


    if (!AuthenticationUtil.isAuthenticated()) {
        return <Redirect to="/login" />;
    }

    if (response.data && response.data.groupQuestionnaires.length === 1) {
        const groupQuestionnaire = response.data.groupQuestionnaires[0];
        const questionRouter = `question/unanswered_by_questionnaire/${groupQuestionnaire.questionnaire.id}/${groupQuestionnaire.id}`;
        return <Redirect to={questionRouter} />;
    }

    return (
        <div>
            <Box m={1}>
                <Grid container justify="flex-end">
                    <Grid item>
                        <VaccineHistory />
                    </Grid>
                    <Grid item>
                        <Logout />
                    </Grid>
                </Grid>
                <Grid container justify="center">
                    <Grid item>
                        <h2>Aktivt frågeformulär</h2>
                    </Grid>
                </Grid>

                <Grid container spacing={2} justify="center">
                    {response.status && response.data.groupQuestionnaires.length > 0 ? response.data.groupQuestionnaires.map(questionnaire =>
                        <Questionnaire key={questionnaire.id} data={questionnaire} />
                    ) : <h3>Tack! Inget mera frågeformulär för dig just nu.</h3>}
                </Grid>
            </Box>
            <Copyright />
            {loader}
            {alert}
        </div>
    );
}