import { Button, Grid } from '@material-ui/core';
import queryString from 'query-string';
import React, { useEffect } from "react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { Copyright } from "../components/Copyright";
import * as ApiUtil from "../util/ApiUtil";
import * as AuthenticationUtil from "../util/AuthenticationUtil";
import * as Endpoints from "../util/Endpoints";
import { useAlert } from "../util/useAlert";
import { usePageLoader } from "../util/usePageLoader";



export const CallbackPage = (props) => {

    const history = useHistory();

    const { search } = useLocation();
    const values = queryString.parse(search);

    const [alert, showAlert] = useAlert();
    const [loader, showLoader, hideLoader] = usePageLoader();

    //const [success, setSuccess] = useState(false);

    useEffect(() => {
        const registerUser = async () => {
            try {
                showLoader();
                let apiUrl = Endpoints.registration + "/" + values.grandidsession + "/" + localStorage.getItem("mobileNumber") + "?";
                if (localStorage.getItem("regionId")) {
                    apiUrl += "regionId=" + localStorage.getItem("regionId") + "&";
                }
                if (localStorage.getItem("siteId")) {
                    apiUrl += "siteId=" + localStorage.getItem("siteId");
                }
                const responseData = await ApiUtil.callBackend(apiUrl, "GET");
                console.log(responseData);

                if (responseData.status) {
                    showAlert(responseData.message, "success");

                    localStorage.setItem("token", responseData.data.token);
                    localStorage.setItem("user", JSON.stringify(responseData.data.user));
                    localStorage.setItem("token_date", new Date());
                    localStorage.setItem("expire_in", responseData.data.expireIn);//Minutes
                    localStorage.setItem("role", JSON.stringify(responseData.data.user.role));
                    localStorage.setItem("authorities", JSON.stringify(responseData.data.user.role.authorities));
                    history.push("/questionnaire");

                    //setSuccess(true);
                } else {
                    showAlert(responseData.message, "error");
                }
            } catch (error) {
                console.log(error);
                showAlert(error.message, "error");
            } finally {
                hideLoader();
            }
        }
        registerUser();
    }, []);

    if (AuthenticationUtil.isAuthenticated()) {
        return <Redirect to="/questionnaire" />;
    }

    /* if (success) {
        return <Redirect to="/login" />;
    } */

    return (
        <div>
            <h1>Registrering pågår</h1>
            <Grid container justify="center">
                <Grid item>
                    <Button variant="outlined" color="primary">
                        <Link to="/questionnaire">
                            Logga in
                        </Link>
                    </Button>
                </Grid>
            </Grid>
            <Copyright />
            {loader}
            {alert}
        </div>
    );
}