import '../App.css';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import * as DateTimeUtil from '../DateTimeUtil';
import { Box, Grid } from '@material-ui/core';


export const Questionnaire = (props) => {
    console.log(props);

    let groupQuestionnaire = props.data;
    let questionnaire = groupQuestionnaire.questionnaire;
    const questionRouter = `question/unanswered_by_questionnaire/${questionnaire.id}/${groupQuestionnaire.id}`

    return (
        <Grid item>
            <Paper elevation={3} className="p2">
                <Box p={3}> //padding =3
                    <h2>{questionnaire.title}</h2>
                    <h5>Start Date: {DateTimeUtil.prepareDateTimeForText(parseInt(groupQuestionnaire.startDateTime))}</h5>
                    <h5>End Date: {DateTimeUtil.prepareDateTimeForText(parseInt(groupQuestionnaire.endDateTime))}</h5>
                    <Button variant="outlined" color="primary">
                        <Link to={questionRouter}>
                            Frågelista
                            </Link>
                    </Button>
                </Box>
            </Paper>
        </Grid >
    );
}