import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { usePageLoader } from './usePageLoader';
import { useAlert } from './useAlert';
import * as ApiUtil from "../util/ApiUtil";
import { prepareDateTimeForText } from './DateTimeUtil';



const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

export const TableUtil = (props) => {
    const columns = props.columns;
    let api = props.api;
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [response, setResponse] = useState({});

    const [loader, showLoader, hideLoader] = usePageLoader();
    const [alert, showAlert] = useAlert();

    useEffect(() => {
        api = api + "?pageNumber=" + page + "&pageSize=" + rowsPerPage;
        const getData = async () => {
            showLoader();
            const responseData = await fetch(api,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("token")
                    }
                }).then(response => {
                    return response.json();
                }).catch(e => {
                    console.log("Error" + e);
                }).finally(() => {
                    hideLoader();
                });
            setResponse(responseData);
            if (!responseData.status) {
                showAlert(responseData.message, "error");
            }
        }
        getData();
    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div>
            {response.status ? (
                <Paper className={classes.root}>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {response.data.list.map((row, index) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                            {columns.map((column, index) => {
                                                let splitted = column.id.split(">")
                                                let value = row[splitted[0]];
                                                if (value) {
                                                    for (let i = 1; i < splitted.length; i++) {
                                                        value = value[splitted[i]];
                                                    }
                                                    if (column.type === 'dateTime') {
                                                        value = prepareDateTimeForText(parseInt(value));
                                                    } else if (column.type === 'nurseFullName') {
                                                        value = value.firstName + " " + value.lastName;
                                                    }
                                                } else {
                                                    value = "";
                                                }
                                                
                                                return (
                                                    <TableCell key={index} align={column.align}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={response.data.list.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
            ) : null}
            {loader}
            {alert}
        </div>
    );
}