import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Redirect, Link } from "react-router-dom";
import * as AuthenticationUtil from "../util/AuthenticationUtil";

import { useForm } from "react-hook-form";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { usePageLoader } from "../util/usePageLoader";
import { useAlert } from "../util/useAlert";

import * as Endpoints from "../util/Endpoints";
import * as ApiUtil from "../util/ApiUtil";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";

export const RegisterPersonNumber = ({
  regionList = [{}],
  siteList = [{}],
}) => {
  const [region, setRegion] = useState();
  const [site, setSite] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const history = useHistory();

  const [redirectUrl, setRedirectUrl] = useState();
  const [proceedRedirect, setProceedRedirect] = useState();

  const [loader, showLoader, hideLoader] = usePageLoader();
  const [alert, showAlert] = useAlert();

  if (AuthenticationUtil.isAuthenticated()) {
    return <Redirect to="/questionnaire" />;
  }

  const registerUser = async (data) => {
    try {
      showLoader();
        if (data.password.toLowerCase() === "pass") {
      showAlert("error");
      return;
    }
      const responseData = await ApiUtil.callBackend(
        Endpoints.registerUserPersonNumber,
        "POST",
        {
          personalNumber: data.personNumber,
          password: data.password,
          firstName: data.firstName,
          lastName: data.lastName,
          mobileNumber: data.mobileNumber,
          regionId: "",
          siteId: "",
        }
      );
     
        if (responseData.status) {
          showAlert(responseData.message, "success");
        } else {
          showAlert(responseData.message, "error");
        }
    } catch (error) {
      console.log(error);
      showAlert(error.message, "error");
      return;
    } finally {
      hideLoader();
    }
  };

  if (proceedRedirect && redirectUrl) {
    window.location = redirectUrl;
  }

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <form onSubmit={handleSubmit(registerUser)} noValidate>
          <TextField
            margin="normal"
            fullWidth
            required
            id="outlined-error-helper-text"
            label="Personnummer (ÅÅÅÅMMDDNNNN)"
            {...register("personNumber", {
              required: {
                value: true,
                message: "Personnummer som krävs.",
              },
              maxLength: {
                value: 12,
                message: "Personnummer måste vara 12-siffrigt.",
              },
              minLength: {
                value: 12,
                message: "Personnummer måste vara 12-siffrigt.",
              },
            })}
            error={Boolean(errors.personNumber)}
            helperText={errors.personNumber?.message}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Lösenord"
            type="password"
            id="password"
            autoComplete="current-password"
            {...register("password", {
              required: {
                value: true,
                message: "Lösenord som krävs.",
              },
            })}
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
          />
          <TextField
            margin="normal"
            fullWidth
            required
            id="first-name"
            label="Förnamn"
            {...register("firstName", {
              required: {
                value: true,
                message: "Förnamn som krävs.",
              },
            })}
            error={Boolean(errors.firstName)}
            helperText={errors.firstName?.message}
          />
          <TextField
            margin="normal"
            fullWidth
            required
            id="last-name"
            label="Efternamn"
            {...register("lastName", {
              required: {
                value: true,
                message: "Efternamn som krävs.",
              },
            })}
            error={Boolean(errors.lastName)}
            helperText={errors.lastName?.message}
          />
          <TextField
            margin="normal"
            fullWidth
            required
            id="mobile-number"
            label="Mobilnummer (+46xxxxxxxxx)"
            {...register("mobileNumber", {
              required: {
                value: true,
                message: "Mobilnummer som krävs.",
              },
            })}
            error={Boolean(errors.mobileNumber)}
            helperText={errors.mobileNumber?.message}
          />

          <Box mt={1}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
            >
              Registrera med lösenord
            </Button>
          </Box>
        </form>
      </Grid>
      {loader}
      {alert}
    </>
  );
};
