import React from "react";
import { useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";

import { Button, TextField, Grid } from "@material-ui/core";

import { usePageLoader } from "../util/usePageLoader";
import { useAlert } from "../util/useAlert";

import * as Endpoints from "../util/Endpoints"; //namespace imports to import large number of exports from a single module
import * as ApiUtil from "../util/ApiUtil";

export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(); //useform hook to handle validation and submission of the form

  const history = useHistory();

  const [loader, showLoader, hideLoader] = usePageLoader();
  const [alert, showAlert] = useAlert();

  const loginUser = async (data) => {
    try {
      showLoader();
      if (data.password.toLowerCase() === "pass"){
        showAlert("error");
        return;
      }
      const responseData = await ApiUtil.callBackend(Endpoints.login, "POST", {
        username: data.username.trim(),
        password: data.password.trim(),
      }); //constant that contains URLof the login endpoint to the backend API
    console.log(responseData);

    if (responseData.password === "pass"){
       window.alert("Failed to Login")
    }else
    {
      if (responseData.status) {
        localStorage.setItem("token", responseData.data.token);
        localStorage.setItem("user", JSON.stringify(responseData.data.user));
        localStorage.setItem("token_date", new Date());
        localStorage.setItem("expire_in", responseData.data.expireIn); //Minutes
        localStorage.setItem(
          "role",
          JSON.stringify(responseData.data.user.role)
        );
        localStorage.setItem(
          "authorities",
          JSON.stringify(responseData.data.user.role.authorities)
        );
        history.push("/questionnaire"); // to navigate to different route
        //history manages browser history stack and allows user to navigate to
        //different page in single page application
      } else {
        showAlert(responseData.message, "error");
      }
    }
    } catch (error) {
      console.log(error);
      showAlert(error.message, "error");
      return;
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <form onSubmit={handleSubmit(loginUser)} noValidate>
          <TextField
            required //mark input as the required field
            id="outlined-error-helper-text"
            label="Personnummer (ÅÅÅÅMMDDNNNN)"
            {...register("username", {
              required: {
                value: true,
                message: "Personnummer som krävs.",
              },
            })}
            error={Boolean(errors.username)} //check for username validation
            helperText={errors.username?.message} //? used to aviod runtime error if password null or undefined
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Lösenord"
            type="password"
            id="password"
            autoComplete="current-password"
            {...register("password", {
              //spread operator to spread the objects
              required: {
                value: true,
                message: "Lösenord som krävs.",
              },
            })}
            error={Boolean(errors.password)} //used for form validation
            helperText={errors.password?.message} //if password is null returns undefined
          />
          <Button type="submit" color="secondary">
            Logga in med lösenord
          </Button>
        </form>
      </Grid>
      {loader} 
      {alert}
    </>
  );
};
