import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Redirect, Link } from "react-router-dom";
import * as AuthenticationUtil from "../util/AuthenticationUtil";

import { useForm } from "react-hook-form";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { usePageLoader } from "../util/usePageLoader";
import { useAlert } from "../util/useAlert";

import * as Endpoints from "../util/Endpoints";
import * as ApiUtil from "../util/ApiUtil";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";

export const Register = ({ regionList = [{}], siteList = [{}] }) => {
  const [region, setRegion] = useState();
  const [site, setSite] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const history = useHistory();

  const [redirectUrl, setRedirectUrl] = useState();
  const [proceedRedirect, setProceedRedirect] = useState();

  const [loader, showLoader, hideLoader] = usePageLoader();
  const [alert, showAlert] = useAlert();

  if (AuthenticationUtil.isAuthenticated()) {
    return <Redirect to="/questionnaire" />;
  }

  

  const handleClickRegister = (data) => {
    const getRedirectUrl = async () => {
      try {
        showLoader();
        localStorage.setItem("mobileNumber", data.mobileNumber);
       
        const responseData = await ApiUtil.callBackend(
          Endpoints.registrationUrl,
          "POST"
        );
        console.log(responseData);

        if (!responseData.status) {
          showAlert(responseData.message, "error");
        }
        setRedirectUrl(responseData.data.redirectResponse.redirectUrl);
        setProceedRedirect(true);
      } catch (error) {
        console.log(error);
        showAlert(error.message, "error");
      } finally {
        hideLoader();
      }
    };
    getRedirectUrl();
  };

  if (proceedRedirect && redirectUrl) {
    window.location = redirectUrl;
  }

  const handleSiteChange = (event, object) => {
    localStorage.setItem("siteId", event.target.value);
    setSite(event.target.value);
  };

  const handleRegionChange = (event, object) => {
    localStorage.setItem("regionId", event.target.value);
    setRegion(event.target.value);
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <form onSubmit={handleSubmit(handleClickRegister)} noValidate>
          <TextField
            margin="normal"
            fullWidth
            required
            id="outlined-error-helper-text"
            label="Mobilnummer (+46xxxxxxxxx)"
            autoFocus
            {...register("mobileNumber", {
              required: {
                value: true,
                message: "Mobilnummer som krävs.",
              },
            })}
            error={Boolean(errors.mobileNumber)}
            helperText={errors.mobileNumber?.message}
          />

          <Box mt={1}>
            <Button type="submit" fullWidth variant="contained" color="primary">
              Registrera dig med BankID
            </Button>
          </Box>
        </form>
      </Grid>
      {loader}
      {alert}
    </>
  );
};
