import '../App.css';
import { Box, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import * as AuthenticationUtil from "../util/AuthenticationUtil";

import { Paper, Button } from '@material-ui/core';

import { Copyright } from '../components/Copyright';

import * as Endpoints from "../util/Endpoints";
import { usePageLoader } from '../util/usePageLoader';
import { useAlert } from '../util/useAlert';

import { Logout } from '../components/Logout';
import { VaccineHistory } from '../components/VaccineHistory';

import { TableUtil } from '../util/TableUtil';
import { prepareDateTimeForText } from '../util/DateTimeUtil';
import { QuestionnaireButton } from '../components/QuestionnaireButton';

export const VaccineHistoryPage = (props) => {


        //   const api = `${Endpoints.vaccineHistory}`;
         const apis =`${Endpoints.sites}`;
      const userId = JSON.parse(localStorage.getItem("user")).id;
      const api = `${Endpoints.vaccineHistory}/${userId}`;

    const [loader, showLoader, hideLoader] = usePageLoader();
    const [alert, showAlert] = useAlert();

    const columns = [
      {
        id: "nurse",
        label: "Barnmorska",
        minWidth: 100,
        type: "nurseFullName",
      },

      {
        id: "vaccinatedDateTime",
        label: "Vaccinationsdatum",
        minWidth: 170,
        type: "dateTime",
      },
      { id: "vaccineBatchNumber", label: "Vaccin batchnummer ", minWidth: 100 },
      { id: "comment", label: "Kommentar", minWidth: 100 },
      { id: "vaccine>name", label: "Vaccin", minWidth: 100 },
      { id: "dose>quantity", label: "Dos", minWidth: 100 },
      { id: "administration>title", label: "Applikation", minWidth: 100 },
      { id: "location>title", label: "Injektionsställe", minWidth: 100 },
      { id: "sampling>title", label: "provtagningssätt", minWidth: 100 },
    ];
    console.log(api)
    console.log(apis)

    if (!AuthenticationUtil.isAuthenticated()) {
        return <Redirect to="/login" />;
    }


    return (
        <>
            <Box m={1}>
                <Grid container justify="flex-end" spacing={1}>
                    <Grid item>
                        <QuestionnaireButton />
                    </Grid>
                    <Grid item>
                        <Logout />
                    </Grid>
                </Grid>


                <Grid container justify="center" align="center">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <h2>Vaccinationshistorik</h2>
                    </Grid>

                    <Grid item xs={12} sm={11} md={10} lg={9}>

                        <Paper>
                            <Box p={1}>
                                
                                        <Grid container spacing={1} align="left">
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                {(<TableUtil
                                                    api={api}
                                                    columns={columns}
                                                />)}
                                            </Grid>
                                        </Grid>
                            </Box>
                        </Paper>

                    </Grid>
                </Grid>
                <Grid container justify="center">
                    <Grid item>
                        <Copyright />
                    </Grid>
                </Grid>
            </Box>

            {loader}
            {alert}
        </>
    );
}
