import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Button } from '@material-ui/core';

export const QuestionnaireButton = () => {

    const [toQuestionnaire, setToQuestionnaire] = useState(false);

    const handleClick = () => {
        setToQuestionnaire(true);
    }

    if (toQuestionnaire) {
        return <Redirect to="/questionnaire" />;
    }

    return (
        <Button
            color="primary"
            onClick={handleClick}>
            Frågeformulär
        </Button>
    );
}