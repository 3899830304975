import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Button } from '@material-ui/core';

export const Logout = () => {

    const [loggedOut, setLoggedOut] = useState(false);

    const handleLogout = () => {
        localStorage.clear();
        if (!localStorage.getItem("token")) {// localStorage.getItem used to get token key value
            setLoggedOut(true);// condition true huda this function is called
        }
    }

    if (loggedOut) {
        return <Redirect to="/" />;
    }

    return (
        <Button
            color="secondary"
            onClick={handleLogout}>
            Logga ut
        </Button>
    );
}